import {
  Details,
  Image,
  //  Member,
  Name,
  NameSub,
  Position,
  //  ThreeCols,
  TwoCols,
} from "../components/atoms/TeamObjs";
import { WhiteBackLayer } from "../components/layout/BackgroundLayer";
import { SingleLayout } from "../components/layout/SingleLayout";

export const TeamBackLayer = () => {
  return null;
};

export const TeamPage = () => {
  return (
    <div>
      <WhiteBackLayer />

      <SingleLayout maxWidth="lg">
        <h1 className="text-xl text-center font-sans italic text-rose-700 ">
          Team
        </h1>

        <div className="mt-12 md:mt-20">
          <TwoCols>
            <Image src="./images/team/shoda_ryo.jpg" alt="" />
            <div>
              <Name>Shoda Ryo</Name>
              <NameSub>庄田 竜</NameSub>
              <Details>
                <Position>株式会社庄田竜事務所 代表取締役社長</Position>
                <p>人々のライフスタイルに衝撃を。</p>
                <p>
                  講演実績として東京大学/京都大学/神戸大学/法政大学/関西学院大学
                </p>
              </Details>
            </div>
          </TwoCols>

          <TwoCols>
            <Image src="./images/team/uozumi_mihoko.jpg" alt="" />
            <div>
              <Name>Uozumi Mihoko</Name>
              <NameSub>魚住 美保子</NameSub>
              <Details>
                <Position>
                  株式会社庄田竜事務所本部
                  <br />
                  NPO法人 次世代リーダー相談所代表理事
                  <br />
                  R&Mストラテジー代表
                </Position>
                <p>
                  24歳から延べ4万人近くの青年達の指導に関わる。現在もスケジュールが空けば相談というライフスタイルを過ごす。キャリア相談、人生相談、経営相談のボランティアのデーターベースは膨大であり、その他、いろいろな業界のスペシャリストのマーケティング、多くの経営者や行政関係者から支援のもと、次世代リーダー相談所を事業として還暦越えても挑戦し続ける覚悟で、事業設立を決意しました。自身の生涯をかけ「1人でも多くの次世代リーダー輩出に貢献したい」、社会で自己実現するには、お金と時間、経済、経営知識とスキルの重要性を拡散し続けるため、日夜活動し続けております。
                </p>
              </Details>
            </div>
          </TwoCols>

          <TwoCols>
            <Image src="./images/team/furuta.jpg?20230728" alt="" />
            <div>
              <Name>Furuta Hiroyuki</Name>
              <NameSub>古田 寛幸</NameSub>
              <Details>
                <Position>
                  ALL ROUNDER 審査部責任者
                  <br />
                  株式会社自分自身 代表取締役
                </Position>
                <p>
                  人々の心に火を灯す。
                  <br />
                  元プロサッカー選手
                  <br />
                  Jリーグ通算239試合出場
                </p>
              </Details>
            </div>
          </TwoCols>

          <TwoCols>
            <Image src="./images/team/shintani_izumi.jpg" alt="" />
            <div>
              <Name>Shintani Izumi</Name>
              <NameSub>新谷 和実</NameSub>
              <Details>
                <Position>
                  ALL ROUNDER 審査部
                  <br />
                  NPO法人The Peace Front 代表理事
                  <br />
                  株式会社The Peace Front 代表取締役
                </Position>
                <p>
                  理念：より良い社会を実現し続ける
                  <br />
                  ビジョン：社会課題解決の量と質を高める 他<br />
                  東京大学大学院生（インパクト評価研究）
                  <br />
                  途上国向け行政プロジェクト参画
                  <br />
                  大学連合事務局員（産官学民連携）
                </p>
              </Details>
            </div>
          </TwoCols>

          <TwoCols>
            <Image src="./images/team/inoue_yusei.jpg" alt="" />
            <div>
              <Name>Inoue Yusei</Name>
              <NameSub>井上 友聖</NameSub>
              <Details>
                <Position>
                  ALL ROUNDER 審査部
                  <br />
                  （一社）少年PROJECT 創業 代表理事
                  <br />
                  AR ACADEMY代表
                </Position>
                <p>
                  理念：解き放つ人生を提供
                  <br />
                  ビジョン：エンタメ・教育・居場所を通して国内の幸福度を上げる
                  <br />
                  うみぞら映画祭2024/2025 運営本部補助
                  <br />
                  大阪・淡路島での若者に向けた居場所の提供
                  <br />
                  多方面でのエンターテイメント演出・企画・照明を担当（Zeppなんば/能勢電鉄/梅田サウスホール/川西バイブルキャンプ場etc）
                </p>
              </Details>
            </div>
          </TwoCols>

          <TwoCols>
            <Image src="./images/team/kimura_yoshiya.jpg" alt="" />
            <div>
              <Name>Kimura Yoshiya</Name>
              <NameSub>木村 仁哉</NameSub>
              <Details>
                <Position>ALL ROUNDER 審査部</Position>
                <p>
                  経歴
                  <br />
                  ・大阪府出身
                  <br />
                  ・岸和田高校卒
                  <br />
                  ・関西大学 初等教育学専修卒
                  <br />
                  学生団体を立ち上げ小中高大生300名以上にキャリア教育実施
                  <br />
                  ・佛教大学教育学部卒
                  <br />
                  ・大阪市小学校教員として半年勤務
                  <br />
                  ・児童福祉施設立ち上げに従事
                  <br />
                  放課後等デイサービス勤務
                </p>
              </Details>
            </div>
          </TwoCols>

          <TwoCols>
            <Image src="./images/team/hano_ryota.jpg" alt="" />
            <div>
              <Name>Hano Ryota</Name>
              <NameSub>杷野 稜大</NameSub>
              <Details>
                <Position>ALL ROUNDER 審査部</Position>
                <p>
                  理念：人生に生きがいを届ける
                  <br />
                  ビジョン：大人のキッザニアを作る
                  <br />
                  NPO法人次世代リーダー相談所 PRアシスタント
                  <br />
                  All Rounder Tクラス運営
                  <br />
                  Ted Talk U-Tokyo Speaker
                </p>
              </Details>
            </div>
          </TwoCols>

          <TwoCols>
            <Image src="./images/team/tanaka_kanon.jpg" alt="" />
            <div>
              <Name>Tanaka Kanon</Name>
              <NameSub>田中 かのん</NameSub>
              <Details>
                <Position>ALL ROUNDER 審査部</Position>
                <p>
                  理念 : “途上国”から世界を変革する
                  <br />
                  ビジョン : 社会課題解決を日常にデザインする
                  <br />
                  東南アジアでのスタディーツアーを企画、運営。
                </p>
              </Details>
            </div>
          </TwoCols>

          <TwoCols>
            <Image src="./images/team/nakatani_daiki.jpg" alt="" />
            <div>
              <Name>Nakatani Daiki</Name>
              <NameSub>中谷 大希</NameSub>
              <Details>
                <Position>ALL ROUNDER 審査部</Position>
                <p></p>
              </Details>
            </div>
          </TwoCols>

          <TwoCols>
            <Image src="./images/team/antoniantz_taiga.jpg" alt="" />
            <div>
              <Name>Antoniantz Taiga</Name>
              <NameSub>アントニアンズ 大我</NameSub>
              <Details>
                <Position>ALL ROUNDER 審査部</Position>
                <p></p>
              </Details>
            </div>
          </TwoCols>

          <TwoCols>
            <Image src="./images/team/kugimiya.jpg" alt="" />
            <div>
              <Name>Kugimiya Mitsumasa</Name>
              <NameSub>釘宮 光政</NameSub>
              <Details>
                <Position>会計士</Position>
                <p></p>
              </Details>
            </div>
          </TwoCols>

          <TwoCols>
            <Image src="./images/team/iwasaki_akihiro.jpg" alt="" />
            <div>
              <Name>Iwasaki Akihiro</Name>
              <NameSub>岩崎 章浩</NameSub>
              <Details>
                <Position>
                  弁護士 弁理士
                  <br />
                  法律事務所HEROリーガルグループ 代表弁護士
                </Position>
                <p>
                  スタートアップ法務（資金調達、上場支援）、AIを含むTechnology法務、ヘルスケアビジネス（医療・介護）、知財法務を得意とする。ほか、大学における学生の指導、ビジネスを促進するための戦略的アドバイス、投資等を行う。
                </p>
              </Details>
            </div>
          </TwoCols>
        </div>

        {/* <div className="mt-12 md:mt-20">
          <ThreeCols>
            <Member
              imgSrc="./images/team/ph150x150.png"
              job="Web Developer"
              name="その他メンバー"
            />
          </ThreeCols>
        </div> */}
      </SingleLayout>
    </div>
  );
};
