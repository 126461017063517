import React, { FC, ImgHTMLAttributes, ReactNode } from "react";

export const TwoCols: FC<{ children?: ReactNode | ReactNode[] }> = ({
  children,
}) => {
  const childrenArray = React.Children.toArray(children);

  return (
    <div className="md:flex justify-between md:-mr-6 lg:lg-10 my-10">
      {childrenArray.map((child, i) => (
        <div key={i} className="basis-1/2 md:pr-8 lg:pr-12">
          {child}
        </div>
      ))}
    </div>
  );
};

export const ThreeCols: FC<{ children?: ReactNode | ReactNode[] }> = ({
  children,
}) => {
  const childrenArray = React.Children.toArray(children);

  return (
    <div className="sm:flex flex-wrap my-8 -mr-4">
      {childrenArray.map((child, i) => (
        <div key={i} className="sm:basis-1/2 md:basis-1/3 pr-4">
          {child}
        </div>
      ))}
    </div>
  );
};

export const Image: FC<ImgHTMLAttributes<HTMLImageElement>> = (props) => {
  return (
    <div className="">
      <img {...props} alt={props.alt} />
    </div>
  );
};

export const Name: FC<{ children: ReactNode }> = ({ children }) => {
  return <p className="mt-3 md:mt-0 text-2xl">{children}</p>;
};

export const NameSub: FC<{ children: ReactNode }> = ({ children }) => {
  return <p className="mt-1.5 mb-2 text-xl font-sans">{children}</p>;
};

export const Position: FC<{ children: ReactNode }> = ({ children }) => {
  return <p className="mt-3 leading-relaxed font-sans">{children}</p>;
};

export const Details: FC<{ children?: ReactNode }> = ({ children }) => {
  return (
    <div className="leading-relaxed text-sm font-sanstext-justify">
      {children}
    </div>
  );
};

type MemberProps = { imgSrc: string; job: string; name: string };

export const Member: FC<MemberProps> = ({ imgSrc, job, name }) => {
  return (
    <div className="flex my-2.5">
      <div className="basis-3/12 sm:basis-4/12">
        <img
          className="block w-full aspect-square object-cover"
          src={imgSrc}
          alt={`${name}の写真`}
        />
      </div>
      <div className="ml-2">
        <p className="font-sans">{job}</p>
        <p className="mt-px text-lg font-sans">{name}</p>
      </div>
    </div>
  );
};
