import { shuffleArray } from "../lib/utils";

type CarddataTypes = {
  [key: string]: {
    id: number;
    philosophy: string;
  };
};

const carddata: CarddataTypes = {
  "312": {
    id: 312,
    philosophy: "未完の心にイノベーションを起こす",
  },
  "707": {
    id: 707,
    philosophy: "心動く空間を、ともに。",
  },
  "1239": {
    id: 1239,
    philosophy: "小さな宝石の未来を輝かせる",
  },
  "1290": {
    id: 1290,
    philosophy: "志を持つ人に、機会と挑戦できる文化を創る。",
  },
  "1563": {
    id: 1563,
    philosophy: "妄想(やってみたい想像)を具現化する",
  },
  "1964": {
    id: 1964,
    philosophy: "新世界に没頭し、好きと個性を活かす",
  },
  "2251": {
    id: 2251,
    philosophy: "Thing is Utopia　ことばで理想の世界をつくる",
  },
  "2465": {
    id: 2465,
    philosophy: "個性を誇れる世界をつくる",
  },
  "2540": {
    id: 2540,
    philosophy: "DREAMTシャツを通して共生の社会に",
  },
  "2690": {
    id: 2690,
    philosophy:
      "クリエイティブでこの上ない喜びと感動を/ 1人でも多くの人が後悔しない世の中に",
  },
  "2755": {
    id: 2755,
    philosophy: "懐かしい未来をつくる",
  },
  "2812": {
    id: 2812,
    philosophy: "Web×ホスピタリティで笑顔を創る",
  },
  "2915": {
    id: 2915,
    philosophy: "好奇心に生きる",
  },
  "3208": {
    id: 3208,
    philosophy: "笑顔100%",
  },
  "3275": {
    id: 3275,
    philosophy: "声にならないこえを聴く。",
  },
  "3299": {
    id: 3299,
    philosophy: "好きは世界を変える",
  },
  "3311": {
    id: 3311,
    philosophy: "職業ネプソン",
  },
  "3318": {
    id: 3318,
    philosophy: "個性を最大化させる",
  },
  "3421": {
    id: 3421,
    philosophy: "少年たちとライフスタイルを解放する",
  },
  "3461": {
    id: 3461,
    philosophy: "日常にひとさじのキラキラを巻き起こす",
  },
  "3479": {
    id: 3479,
    philosophy: "いのちのままに生きる人を増やす。",
  },
  "3773": {
    id: 3773,
    philosophy: "アフリカで未来ある若者の「在りたい姿」を実現させる",
  },
  "3808": {
    id: 3808,
    philosophy: "夢に満ち溢れた人々からなる生き生きとした社会の創造",
  },
  "3828": {
    id: 3828,
    philosophy: "遊びと学びのシームレス実現",
  },
  "3835": {
    id: 3835,
    philosophy: "Freedom Dive",
  },
  "3836": {
    id: 3836,
    philosophy:
      "孤高の人（好きものなやつら）を蘇生し、世界を解放する　鍼を打ち状態を良くし続ける　新世界を提供する。",
  },
  "3857": {
    id: 3857,
    philosophy: "孤独な表現者の魅力を解き放つ",
  },
  "3858": {
    id: 3858,
    philosophy: "やさしい人と華ひらく / 世界を味わう",
  },
  "3866": {
    id: 3866,
    philosophy: "魅力を共創する",
  },
  "3868": {
    id: 3868,
    philosophy: "ワクワクで世の中を興奮させる！！",
  },
  "3905": {
    id: 3905,
    philosophy: "１人でも多くの人の運命を変える革命を起こす",
  },
  "3906": {
    id: 3906,
    philosophy: "裏も表もここでは出す。",
  },
  "3948": {
    id: 3948,
    philosophy: "世界中の毎日を踊らせる",
  },
  "3950": {
    id: 3950,
    philosophy: "幸せの核心に触れる瞬間を共に",
  },
  "3975": {
    id: 3975,
    philosophy: "自由を伴に謳歌する",
  },
  "3978": {
    id: 3978,
    philosophy: "こだわりを護り続ける",
  },
  "4008": {
    id: 4008,
    philosophy: "努力に報いる",
  },
  "4040": {
    id: 4040,
    philosophy: "本心のままに生きる",
  },
  "4084": {
    id: 4084,
    philosophy: "感動を届ける人材になる",
  },
  "4096": {
    id: 4096,
    philosophy: "人生に生きがいを届ける",
  },
  "4127": {
    id: 4127,
    philosophy: "ISAIを放て",
  },
  "4157": {
    id: 4157,
    philosophy: "挑戦の活力を繋ぐ　",
  },
  "4158": {
    id: 4158,
    philosophy: "可能性を引き上げる",
  },
  "4159": {
    id: 4159,
    philosophy: "空を飛べる人を増やす。",
  },
  "4164": {
    id: 4164,
    philosophy: "概念の起点になる",
  },
  "4168": {
    id: 4168,
    philosophy: "感動の連続に生き、人生に感動を",
  },
  "4171": {
    id: 4171,
    philosophy: "豊かさを育む",
  },
  "4183": {
    id: 4183,
    philosophy: "人の隠れている本質「愛」を呼び覚ます",
  },
  "4213": {
    id: 4213,
    philosophy: "ありのままを生きる居場所づくり",
  },
  "4253": {
    id: 4253,
    philosophy: "歴史へのきっかけを描く",
  },
  "4271": {
    id: 4271,
    philosophy: "主人公に導く",
  },
  "4276": {
    id: 4276,
    philosophy: "日常に彩りを与える",
  },
  "4291": {
    id: 4291,
    philosophy: "進化していこう",
  },
  "4321": {
    id: 4321,
    philosophy: "世界を広げる",
  },
  "4340": {
    id: 4340,
    philosophy: "好奇心で人生を解放する",
  },
  "4342": {
    id: 4342,
    philosophy: "幸せ・温かさを紡ぐ",
  },
  "4348": {
    id: 4348,
    philosophy: "挑戦し続ける",
  },
  "4354": {
    id: 4354,
    philosophy: "「誠実な人が報われる世界を創る」",
  },
  "4358": {
    id: 4358,
    philosophy: "HIPHOPを体現する",
  },
  "4370": {
    id: 4370,
    philosophy: "生を生き返らせる",
  },
  "4381": {
    id: 4381,
    philosophy: "誠実に生きる",
  },
  "4384": {
    id: 4384,
    philosophy: "じゅんわり温もりのあるイラストで　しゅんとした心を癒す",
  },
  "4387": {
    id: 4387,
    philosophy: "素直な自己決定を引き出す",
  },
  "4410": {
    id: 4410,
    philosophy: "想いを形にする機会と環境をつくり、可能性を引き出す",
  },
  "4411": {
    id: 4411,
    philosophy: "人々を奮い立たせ後悔しない人生に導く",
  },
  "4421": {
    id: 4421,
    philosophy: "主人公で在れ",
  },
  "4427": {
    id: 4427,
    philosophy: "「我が道を行ける人を増やす」",
  },
  "4435": {
    id: 4435,
    philosophy: "あなたの居場所にヒカリを灯す",
  },
  "4439": {
    id: 4439,
    philosophy: "愛をもって生きる人をつくる",
  },
  "4459": {
    id: 4459,
    philosophy: "憧れを上書きする",
  },
  "4463": {
    id: 4463,
    philosophy: "生きがいに生きる",
  },
  "4470": {
    id: 4470,
    philosophy: "最高に我儘に生きる",
  },
  "4471": {
    id: 4471,
    philosophy: "原石に研磨剤を。",
  },
  "4475": {
    id: 4475,
    philosophy: "純度100%の自分を表現する",
  },
  "4479": {
    id: 4479,
    philosophy: "全ての人が自分らしく輝ける環境を創る",
  },
  "4480": {
    id: 4480,
    philosophy: "真の課題解決をする。何事にも囚われず。",
  },
  "4482": {
    id: 4482,
    philosophy: "感性を揺さぶる",
  },
  "4484": {
    id: 4484,
    philosophy: "化学反応を創造する",
  },
  "4489": {
    id: 4489,
    philosophy: "関わりを通して”生きづらさ”を解消する",
  },
  "4491": {
    id: 4491,
    philosophy: "埋もれた価値を掘り起こす",
  },
  "4496": {
    id: 4496,
    philosophy: "福山のガキに救いの手を",
  },
  "4501": {
    id: 4501,
    philosophy: "自分らしく(keep it real)幸せに。",
  },
  "4506": {
    id: 4506,
    philosophy: "衝撃的な彩りを創造する",
  },
  "4525": {
    id: 4525,
    philosophy: "人々の青春を加速させる",
  },
  "4528": {
    id: 4528,
    philosophy: "好きを凌駕する",
  },
  "4532": {
    id: 4532,
    philosophy: "『夢や希望を持ち続けられる人を創る』",
  },
  "4540": {
    id: 4540,
    philosophy: "個性を引き出し、自分らしさをコーディネートする",
  },
  "4542": {
    id: 4542,
    philosophy: "無理せず頑張れる環境をつくり、後押しする。",
  },
  "4543": {
    id: 4543,
    philosophy: "押しただけで終わらせない since 2020/05/06",
  },
  "4545": {
    id: 4545,
    philosophy: "叫べる場所を増やす",
  },
  "4548": {
    id: 4548,
    philosophy: "燻る人を太陽に",
  },
  "4555": {
    id: 4555,
    philosophy: "多くの若者へ夢を",
  },
  "4556": {
    id: 4556,
    philosophy: "”ピョン”ってさせる",
  },
  "4561": {
    id: 4561,
    philosophy: "学術界にrevolutionを",
  },
  "4566": {
    id: 4566,
    philosophy: "束縛されない。",
  },
  "4568": {
    id: 4568,
    philosophy: "自分らしさを表現する",
  },
  "4569": {
    id: 4569,
    philosophy: "人生を真剣に遊ぶ",
  },
  "4572": {
    id: 4572,
    philosophy: "「自分の真心に正直に」",
  },
  "4577": {
    id: 4577,
    philosophy: "後悔、諦念のない挑戦をつくる",
  },
  "4578": {
    id: 4578,
    philosophy: "『つながり、巡る』せかいをつくる",
  },
  "4579": {
    id: 4579,
    philosophy: "未来にわくわくを届ける",
  },
  "4581": {
    id: 4581,
    philosophy: "経済の力でこの国を変える！",
  },
  "4583": {
    id: 4583,
    philosophy: "シンプルに生きる",
  },
  "4589": {
    id: 4589,
    philosophy: "必然は必善",
  },
  "4590": {
    id: 4590,
    philosophy: "手を伸ばして生きる",
  },
  "4591": {
    id: 4591,
    philosophy: "好きをリアルに。",
  },
  "4596": {
    id: 4596,
    philosophy: "ウガンダと日本の間に「人と人の繋がり」を作る",
  },
  "4599": {
    id: 4599,
    philosophy: "人生に心彩る一瞬を刻む",
  },
  "4600": {
    id: 4600,
    philosophy: "優しく生きられる人を増やしたい？？",
  },
  "4610": {
    id: 4610,
    philosophy: "自分という武器で飛び出していく",
  },
  "4613": {
    id: 4613,
    philosophy: "自己変革で世界を変える",
  },
  "4615": {
    id: 4615,
    philosophy: "人と地球の健康がもたらされた社会の担い手になる",
  },
  "4617": {
    id: 4617,
    philosophy: "世界にハートを突きつける",
  },
  "4622": {
    id: 4622,
    philosophy: "コラボを通して人生を表現する",
  },
  "4624": {
    id: 4624,
    philosophy: "利己を突き詰めれば利他になる",
  },
  "4634": {
    id: 4634,
    philosophy: "幸せな人で溢れる世界を",
  },
  "4638": {
    id: 4638,
    philosophy: "個性が認められる環境をつくる",
  },
  "4648": {
    id: 4648,
    philosophy: "あらゆるものの個性を再発見し、より豊かな暮らしを提案する",
  },
  "4649": {
    id: 4649,
    philosophy: "手を差し伸べて全員を笑顔に",
  },
  "4652": {
    id: 4652,
    philosophy: "未来ある若者に恩送りを",
  },
  "4654": {
    id: 4654,
    philosophy:
      "誰もが自信をもって生きられる世界を作る。　「死」の授業をみんなに。",
  },
  "4658": {
    id: 4658,
    philosophy: "成長欲のある人間を自己実現させる。",
  },
  "4672": {
    id: 4672,
    philosophy: "多様性を尊重される社会で自由に生きる",
  },
  "4679": {
    id: 4679,
    philosophy: "豊かさに生き切る",
  },
  "4680": {
    id: 4680,
    philosophy: "あなた基準で最高に人生を楽しむあなたに導き走らせる！",
  },
  "4683": {
    id: 4683,
    philosophy: "笑顔と活気にあふれた社会をつくる",
  },
  "4684": {
    id: 4684,
    philosophy: "色がある世界 色がない世界にいる人がいるなら色づけたい",
  },
  "4688": {
    id: 4688,
    philosophy:
      "完全燃焼できたなと自分自身が納得できるライフを送れる人になる！",
  },
  "4693": {
    id: 4693,
    philosophy: "日常を熱狂で包み込む",
  },
  "4700": {
    id: 4700,
    philosophy: "皆が立ち寄る相談所",
  },
  "4702": {
    id: 4702,
    philosophy: "架け橋になる",
  },
  "4704": {
    id: 4704,
    philosophy:
      "「夢」「幸せ」の実現　→　人々の日常の中に「応援」できる瞬間を増やす",
  },
  "4708": {
    id: 4708,
    philosophy: "ST~夢の中に自分を置け！",
  },
  "4712": {
    id: 4712,
    philosophy: "クリエイティブで個性を爆発させる",
  },
  "4713": {
    id: 4713,
    philosophy: "本来の自分を解放する",
  },
  "4718": {
    id: 4718,
    philosophy: "居心地が良い社会を作ること",
  },
  "4723": {
    id: 4723,
    philosophy: '"カワイイヤツ"に一歩踏み出すきっかけを',
  },
  "4725": {
    id: 4725,
    philosophy: "トラの眼",
  },
  "4727": {
    id: 4727,
    philosophy: "人々に第二の故郷を。",
  },
  "4728": {
    id: 4728,
    philosophy: "おもろいことしよう（面白さの先の結果）",
  },
  "4730": {
    id: 4730,
    philosophy: "美しく勝つ",
  },
  "4736": {
    id: 4736,
    philosophy: "学生の可能性・選択肢を広げる",
  },
  "4737": {
    id: 4737,
    philosophy: "世の中に新たな価値を創出し続ける！",
  },
  "4738": {
    id: 4738,
    philosophy: "生まれた場所に関わらず誰もが自由に選択できる世界",
  },
  "4749": {
    id: 4749,
    philosophy: "戯曲「松葉大聖」",
  },
  "4753": {
    id: 4753,
    philosophy: "※あともう少し",
  },
  "4755": {
    id: 4755,
    philosophy: "価値観を広げ、柔軟に自由な人生を創る",
  },
  "4759": {
    id: 4759,
    philosophy: "心と心を繋ぐ",
  },
  "4777": {
    id: 4777,
    philosophy: "線の束縛、彩の自由",
  },
  "4784": {
    id: 4784,
    philosophy: "一人一人の飛躍起点になる",
  },
  "4785": {
    id: 4785,
    philosophy: "生涯、仲間と成長、進化し続け変化しつつづける日常",
  },
  "4786": {
    id: 4786,
    philosophy: "「おすし」であふれる世界をつくろっ",
  },
  "4793": {
    id: 4793,
    philosophy: "思考整理中",
  },
  "4800": {
    id: 4800,
    philosophy: "知った責任を果たす",
  },
  "4803": {
    id: 4803,
    philosophy:
      "唯一無二の存在で溢れた、否定も比較も無い優しい社会を創りたい。",
  },
  "4805": {
    id: 4805,
    philosophy: "100年後に「住める地球」と「食の幸せ」を残す",
  },
  "4811": {
    id: 4811,
    philosophy: "子どもたちから学ぶ。歩む。創る。",
  },
  "4812": {
    id: 4812,
    philosophy: "暖　太陽　自然",
  },
  "4817": {
    id: 4817,
    philosophy: "人々が安心して自己実現できる環境をつくる",
  },
  "4818": {
    id: 4818,
    philosophy: "自分の個性(とげ)を愛し貫ける人を増やす",
  },
  "4821": {
    id: 4821,
    philosophy: "100年後にも「住める地球」と「食の幸せ」を残す",
  },
  "4830": {
    id: 4830,
    philosophy: "食で人生を彩れる人を増やす",
  },
  "4836": {
    id: 4836,
    philosophy: "想いを抱きしめる人達と心昂る世界を創る",
  },
  "4837": {
    id: 4837,
    philosophy: "世界の人々に楽しみを",
  },
  "4842": {
    id: 4842,
    philosophy: "縛りを感じている人に翼を授ける",
  },
  "4845": {
    id: 4845,
    philosophy: "自分の好きなことで人を楽しませたい",
  },
  "4854": {
    id: 4854,
    philosophy: "国際交流を通して人々に楽しみを与える",
  },
  "4857": {
    id: 4857,
    philosophy: "Be Real Be 理ある",
  },
} as const;

const carddataArray = Object.values(carddata);
export const carddataShuffledArray = shuffleArray(carddataArray);
